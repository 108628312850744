import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { RootState } from '..';
import { api } from '../../api';
import { authRefresh } from '../auth';
import { getRights } from '../rights';

export type Profile = 'admin' | 'staff' | 'coach' | 'player' | 'viewer';

export type Permission = 'rank' | 'unit' | 'tourn' | 'enroll' | 'chall';

export type Access = {
	id?: string;
	item: string;
	mail: string;
	name?: string;
	permission: Permission;
	profile: Profile;
	active?: boolean;
	status?: 'new' | 'changed' | 'loading' | 'error';
};

interface AccessState {
	access: Access[];
	send: boolean;
	loading: boolean;
	error?: SerializedError;
}

const initialState: AccessState = {
	access: [],
	send: false,
	loading: false,
};

const accessSlice = createSlice({
	name: 'access',
	initialState,
	reducers: {
		addAccess: (
			state,
			{
				payload,
			}: PayloadAction<Pick<Access, 'item' | 'permission' | 'profile'>>
		) => {
			const existingNew = state.access.some((acc) => !acc.id);

			if (!existingNew) {
				state.access = state.access.concat({
					name: '',
					mail: '',
					item: payload.item,
					permission: payload.permission,
					profile: payload.profile,
					status: 'new',
				});
			}
		},
		changeAccess: (
			state,
			{
				payload,
			}: PayloadAction<{ id?: string; field: keyof Access; value: string }>
		) => {
			const existingMail = state.access.some(
				(acc) => payload.field === 'mail' && acc.mail === payload.value
			);

			state.access = state.access.map((acc) =>
				(payload.id && acc.id === payload.id) || (!payload.id && !acc.id)
					? {
							...acc,
							...(!existingMail
								? {
										[payload.field]: payload.value,
										status:
											acc[payload.field] !== payload.value
												? 'changed'
												: acc['status'],
									}
								: {}),
						}
					: acc
			);
		},
		removeAccess: (state) => {
			state.access = state.access.filter((acc) => !!acc.id);
		},
		setSend: (state) => {
			state.send = true;
		},
		resetAccess: (state) => {
			state.access = initialState.access;
			state.loading = initialState.loading;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getAccess.fulfilled, (state, { payload }) => {
				state.access = [
					...state.access.filter(
						(a) => !payload.length || payload.some((p) => p.id !== a.id)
					),
					...payload.map(
						(a) => (!a.id ? { ...a, status: 'new' } : a) as Access
					),
				];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getAccess.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getAccess.rejected, (state, { error }) => {
				state.loading = false;
				state.error = error;
			});
		builder
			.addCase(insertAccess.fulfilled, (state, { payload }) => {
				state.access = state.access.some((acc) => acc.id === payload.id)
					? state.access.map((acc) => (acc.id === payload.id ? payload : acc))
					: state.access.filter((acc) => !!acc.id).concat(payload);
				state.send = false;
				state.error = undefined;
			})
			.addCase(insertAccess.pending, (state, { meta }) => {
				state.access = state.access.some((acc) => acc.id === meta.arg.id)
					? state.access.map((acc) => ({
							...acc,
							...(acc.id === meta.arg.id ? { status: 'loading' } : {}),
						}))
					: state.access.concat({ ...meta.arg, status: 'loading' });
			})
			.addCase(insertAccess.rejected, (state, { payload, meta }) => {
				state.access = state.access.some((acc) => acc.id === meta.arg.id)
					? state.access.map((acc) => ({
							...acc,
							...(acc.id === meta.arg.id ? { status: 'error' } : {}),
						}))
					: state.access.concat({ ...meta.arg, status: 'error' });
				state.error = payload;
			});
	},
});

export const getAccess = createAsyncThunk<
	Access[],
	Pick<Access, 'item' | 'permission'> &
		Partial<Pick<Access, 'mail' | 'profile'>>,
	{ rejectValue: SerializedError }
>(
	'access/getAccess',
	async (
		{ item, permission, mail, profile },
		{ dispatch, rejectWithValue }
	) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.access.get(item, permission, mail, profile);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const insertAccess = createAsyncThunk<
	Access,
	Access,
	{ rejectValue: SerializedError; state: RootState }
>(
	'access/insertAccess',
	async (access, { dispatch, getState, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.access.post(access);

			const userMail = getState().user.attributes?.find(
				(attr) => attr.Name === 'email'
			);

			if (userMail?.Value === access.mail) dispatch(getRights());

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const { addAccess, changeAccess, removeAccess, resetAccess, setSend } =
	accessSlice.actions;

export default accessSlice.reducer;
