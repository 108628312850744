import axios from 'axios';

import { API_URL } from '../config/config';
import { Access, Permission, Profile } from '../store/access';
import { Allocation } from '../store/allocations';
import { Challenge } from '../store/challenges';
import { Championship } from '../store/champs';
import { Enrollment, Participant, Status } from '../store/enrollments';
import { Event } from '../store/events';
import { Tournament } from '../store/events';
import { Group, Team } from '../store/groups';
import { Institution } from '../store/insitutions';
import { Landing } from '../store/landings';
import { League, Ranking } from '../store/leagues';
import { Match } from '../store/matches';
import { Occasion } from '../store/occasion';
import { Court, Organization, Unit } from '../store/organizations';
import { Person } from '../store/people';
import { Plan } from '../store/plans';
import { Balance, Points, Result } from '../store/rankings';
import { Redirect } from '../store/redirects';
import { RightItem } from '../store/rights';
import { SportItem } from '../store/sports';
import { StatisticItem } from '../store/statistics';

export const newAPI = axios.create({
	baseURL: `${API_URL}${
		process.env.REACT_APP_ENV?.slice(0, 3) ??
		process.env.NODE_ENV?.slice(0, 3) ??
		'dev'
	}`,
});

const s3Request = axios.create({
	headers: {
		Authorization: undefined,
	},
});

export const api = {
	access: {
		get: (
			item: string,
			permission: Permission,
			mail?: string,
			profile?: Profile
		) =>
			newAPI.get<Access[]>('/permissions', {
				params: {
					item,
					permission,
					mail,
					profile,
				},
			}),
		post: (access: Access) => newAPI.post<Access>('/permissions', access),
	},
	allocations: {
		post: (allocations: Allocation[]) =>
			newAPI.post<Allocation[]>('/allocations', allocations),
		get: (unit: string) =>
			newAPI.get('/allocations', {
				params: {
					unit,
				},
			}),
		delete: (court: string, day: number, start: string) =>
			newAPI.delete<Allocation>('/allocations', {
				params: {
					court,
					day,
					start,
				},
			}),
	},
	challenges: {
		get: (rank: string) =>
			newAPI.get<Challenge[]>('/challenges', { params: { rank } }),
		post: (challenge: Partial<Challenge>) =>
			newAPI.post<Challenge>('/challenges', challenge),
		close: (challenge: Partial<Challenge>) =>
			newAPI.patch<Challenge>(`/challenges/${challenge.id}`, undefined, {
				params: { rank: challenge.rank },
			}),
	},
	championships: {
		get: (tourn: string) =>
			newAPI.get<Championship>('/championships', {
				params: {
					tourn,
				},
			}),
	},
	classifications: {
		get: (rank: string) =>
			newAPI.get('/classifications', {
				params: {
					rank,
				},
			}),
	},
	courts: {
		get: (unit: string) =>
			newAPI.get<Court[]>('/courts', {
				params: {
					unit,
				},
			}),
		post: (court: Partial<Court>) => newAPI.post<Court>('/courts', court),
		switch: (id: string, activate: boolean) =>
			newAPI.patch<Court>(`/courts/${id}`, { activate }),
	},
	documents: {
		put: (url: string, file: Blob) => s3Request.put(url, file),
	},
	enrollments: {
		id: (enroll: Permission, item: string, id: string) =>
			newAPI.get<Enrollment>(`/enrollments${id ? `/${id}` : ''}`, {
				params: {
					enroll,
					item,
				},
			}),
		get: (enroll: Permission, item: string) =>
			newAPI.get<Enrollment[]>(`/enrollments`, {
				params: {
					enroll,
					item,
				},
			}),
		post: (enrollment: Partial<Enrollment>) =>
			newAPI.post<Enrollment>('/enrollments', enrollment),
		switch: (enrollment: Partial<Enrollment>) =>
			newAPI.patch<Enrollment>('/enrollments', enrollment),
	},
	events: {
		get: () => newAPI.get<Event[]>('/events'),
		post: (event: Partial<Event>) => newAPI.post<Event>('/events', event),
		switch: (id: string, activate: boolean) =>
			newAPI.patch<Event>(`/events/${id}`, { activate }),
	},
	groups: {
		get: (tourn: string) =>
			newAPI.get<Group[]>('/groups', {
				params: {
					tourn,
				},
			}),
		post: (group: Partial<Group>) => newAPI.post<Group>('/groups', group),
		close: (group: Partial<Group>, teams: { [key: number]: Team | null }) =>
			newAPI.patch<Group[]>(`/groups/${group.id}`, teams, {
				params: { tourn: group.tourn },
			}),
		delete: (group: Partial<Group>) =>
			newAPI.delete(`/groups/${group.id}`, {
				params: { tourn: group.tourn },
			}),
		teams: {
			post: (
				group: Partial<Group>,
				teams: { [key: number]: Team | null },
				looser: boolean
			) =>
				newAPI.post<Group>(`/groups/${group.id}`, teams, {
					params: { tourn: group.tourn, looser },
				}),
			delete: (group: Partial<Group>, position: number, looser: boolean) =>
				newAPI.delete<Group>(`/groups/${group.id}/${position}`, {
					params: { tourn: group.tourn, looser },
				}),
		},
		rounds: {
			define: (group: Partial<Group>, rounds: number) =>
				newAPI.put<Group>(
					`/groups/${group.id}`,
					{ rounds },
					{
						params: { tourn: group.tourn },
					}
				),
		},
	},
	happening: {
		get: ({ event, league }: { event?: string; league?: string }) =>
			newAPI.get<{
				sports: SportItem[];
				event: Event;
				tourns: Tournament[];
				units: (Unit & { courts: Court[] })[];
			}>('/happening', { params: { event, league } }),
		contest: {
			get: (id: string) =>
				newAPI.get<{
					event: Event;
					tourn: Tournament;
					groups: Group[];
					matches: Match[];
				}>(`/happening/${id}`),
		},
	},
	institutions: {
		get: (org: string) =>
			newAPI.get<Institution>('/institutions', { params: { org } }),
	},
	landings: {
		get: (land: string) =>
			newAPI.get<Landing>('/landings', { params: { id: land } }),
	},
	leagues: {
		get: () => newAPI.get<League[]>('/leagues'),
		post: (league: Partial<League>) => newAPI.post<League>('/leagues', league),
		switch: (id: string, activate: boolean) =>
			newAPI.patch<League>(`/leagues/${id}`, { activate }),
	},
	matches: {
		get: ({ tourn, rank }: { tourn?: string; rank?: string }) =>
			newAPI.get<Match[]>('/matches', {
				params: {
					tourn,
					rank,
				},
			}),
		post: (match: Partial<Match>) => newAPI.post<Match>('/matches', match),
		delete: (match: Pick<Match, 'id' | 'tourn' | 'group' | 'rank' | 'chall'>) =>
			newAPI.delete<Match>('/matches', {
				params: {
					id: match.id,
					...(match.tourn
						? {
								tourn: match.tourn,
								group: match.group,
							}
						: {}),
					...(match.rank
						? {
								rank: match.rank,
								chall: match.chall,
							}
						: {}),
				},
			}),
	},
	occasion: {
		get: (event: string) =>
			newAPI.get<Occasion>('/occasion', {
				params: {
					event,
				},
			}),
	},
	ocupations: {
		get: (accessToken: string, court: string) =>
			newAPI.get('/ocupations', {
				params: {
					court,
				},
			}),
	},
	organizations: {
		get: () => newAPI.get<Organization[]>('/organizations'),
		post: (organization: Partial<Organization>) =>
			newAPI.post<Organization>('/organizations', organization),
		switch: (id: string, activate: boolean) =>
			newAPI.patch<Organization>(`/organizations/${id}`, { activate }),
	},
	participations: {
		get: (item: string, enroll: Permission) =>
			newAPI.get<(Participant & Status)[]>('/participations', {
				params: {
					item: item, // id do rank ou tourn ou unit
					enroll: enroll, // tipo do enroll
				},
			}),
		post: (
			item: string,
			enroll: Permission,
			participant: Partial<Participant>
		) =>
			newAPI.post<Participant & Status>('/participations', participant, {
				params: {
					item: item, // id do rank ou tourn ou unit
					enroll: enroll, // tipo do enroll
				},
			}),
		switch: (
			item: string,
			permission: Permission,
			enroll: string,
			mail: string,
			confirm?: Extract<
				Status['status'],
				'confirmed' | 'pending_confirmation' | 'pending_payment'
			>,
			activate?: boolean
		) =>
			newAPI.patch<Participant & Status>(
				'/participations',
				{ activate, confirm },
				{
					params: {
						item, // id do rank ou tourn ou unit
						permission, // tipo de permissão
						enroll, // id do enroll
						mail, // e-mail do participante
					},
				}
			),
	},
	people: {
		get: (unit: string, name?: string) =>
			newAPI.get<Person[]>('/people', {
				params: {
					unit,
					name,
				},
			}),
	},
	person: {
		get: (org: string, mail: string, unit: string) =>
			newAPI.get<
				Person & {
					participations?: Participant[];
					enrollments?: Enrollment[];
					tournaments?: Tournament[];
					rankings?: Ranking[];
					matches?: Match[];
				}
			>(`/people/${org}`, { params: { mail, unit } }),
		post: (person: Person) =>
			newAPI.post<Person>(`/people/${person.org}`, person),
	},
	places: {
		get: (unit: string) =>
			newAPI.get<Organization>('/places', {
				params: {
					unit,
				},
			}),
	},
	plans: {
		get: () => newAPI.get<Plan[]>('/plans'),
	},
	ranking: {
		get: (ranking: string) => newAPI.get<Ranking>(`/rankings/${ranking}`),
	},
	rankings: {
		get: (lag?: string) =>
			newAPI.get<Ranking[]>(`/rankings`, { params: { lag } }),
		post: (rank: Partial<Ranking>) => newAPI.post<Ranking>('/rankings', rank),
		switch: (id: string, activate: boolean) =>
			newAPI.patch<Ranking>(`/rankings/${id}`, { activate }),
		balances: {
			get: (rank: string, mail?: string, date?: string) =>
				newAPI.get<Balance[]>(`/rankings/${rank}/balances`, {
					params: {
						mail: mail,
						date: date,
					},
				}),
		},
		points: {
			get: (
				rank: string,
				params: { mail?: string; team?: string; date?: string }
			) =>
				newAPI.get<Points[]>(`/rankings/${rank}/points`, {
					params,
				}),
			patch: (points: Partial<Points>) =>
				newAPI.patch<Points>(`/rankings/${points.rank}/points`, points),
		},
		results: {
			post: (result: Result) =>
				newAPI.post<Points[]>(`/rankings/${result.rank}/points`, result),
		},
	},
	redirects: {
		get: (org: string, redirect?: string) =>
			newAPI.get<Redirect | Redirect[]>('/redirects', {
				params: {
					org,
					redirect,
				},
			}),
	},
	rights: {
		get: () => newAPI.get<RightItem[]>('/rights'),
	},
	sports: {
		get: () => newAPI.get<SportItem[]>('/sports'),
	},
	statistics: {
		get: (id: string, type: Permission) =>
			newAPI.get<StatisticItem>(`/statistics/${type}s/${id}`),
	},
	tournaments: {
		get: (evt?: string) =>
			newAPI.get<Tournament[]>('/tournaments', {
				params: {
					evt,
				},
			}),
		post: (tourn: Partial<Tournament>) =>
			newAPI.post<Tournament>('/tournaments', tourn),
		switch: (id: string, activate?: boolean, publish?: boolean) =>
			newAPI.patch<Tournament>(`/tournaments/${id}`, { activate, publish }),
	},
	units: {
		get: (org?: string) =>
			newAPI.get<Unit[]>('/units', {
				params: {
					org,
				},
			}),
		post: (unit: Partial<Unit>) => newAPI.post<Unit>('/units', unit),
		switch: (id: string, activate: boolean) =>
			newAPI.patch<Unit>(`/units/${id}`, { activate }),
	},
};
