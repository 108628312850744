import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { MessageProps } from 'semantic-ui-react';

import { api } from '../../api';
import checkValidity from '../../util/valida/check';
import { Profile } from '../access';
import { authRefresh } from '../auth';
import { Draw } from '../groups';
import { Age, Category } from '../leagues';
import { Gender } from '../people';
import { Criteria, Score, Sport } from '../sports';

export type EditItem<EditType> = {
	value: EditType;
	changed: boolean;
	error?: boolean;
	required?: boolean;
};

export type Draws = {
	draw: Draw;
	en_US: string;
	pt_BR: string;
	symbol: string;
}[];

export type Fase = {
	score?: Score;
	draw?: Draw;
	pass?: number;
	points?: number;
	reduct?: number;
	earnings?: number[];
	criteria?: Criteria[];
	third?: boolean;
};

export type EditFase = {
	score: EditItem<Fase['score']>;
	draw: EditItem<Fase['draw']>;
	pass: EditItem<Fase['pass']>;
	points: EditItem<Fase['points']>;
	reduct: EditItem<Fase['reduct']>;
	earnings: EditItem<Fase['earnings']>;
	criteria: EditItem<Fase['criteria']>;
	third: EditItem<Fase['third']>;
};

export type Tournament = {
	id: string;
	event: string;
	name: string;
	sign: string;
	badge?: string;
	age?: Age;
	begin?: string;
	category?: Category;
	entry?: string;
	gender?: Gender;
	place?: string;
	ranking?: string;
	sport?: Sport;
	team?: number;
	fases?: Fase[];
	created?: string;
	public?: boolean;
	active?: boolean;
	access?: Profile[];
};

export type EditTourn = {
	id?: string;
	event: Tournament['event'];
	name: EditItem<Tournament['name']>;
	age: EditItem<Tournament['age']>;
	badge: EditItem<Tournament['badge']>;
	begin: EditItem<Tournament['begin']>;
	category: EditItem<Tournament['category']>;
	entry: EditItem<Tournament['entry']>;
	gender: EditItem<Tournament['gender']>;
	place: EditItem<Tournament['place']>;
	ranking: EditItem<Tournament['ranking']>;
	sign: EditItem<Tournament['sign']>;
	sport: EditItem<Tournament['sport']>;
	team: EditItem<Tournament['team']>;
	fases: EditFase[];
	active: boolean;
};

export type Document = 'rules';

export type Documents = {
	[key in Document]?: string;
};

export type Allowed = 'everyone' | 'members' | 'players' | 'admins';

export type Event = {
	id: string;
	org: string;
	name?: string;
	edition?: number;
	link?: string;
	poster?: string;
	documents?: Documents;
	visibility?: Extract<Allowed, 'everyone' | 'members'>;
	alteration?: Extract<Allowed, 'players' | 'admins'>;
	created?: string;
	active?: boolean;
};

export type EditEvt = {
	id?: string;
	org: EditItem<Event['org']>;
	name: EditItem<Event['name']>;
	edition: EditItem<Event['edition']>;
	link: EditItem<Event['link']>;
	poster: EditItem<Event['poster']>;
	documents?: {
		[key in Document]?: EditItem<Documents[key]>;
	};
	visibility: EditItem<Event['visibility']>;
	alteration: EditItem<Event['alteration']>;
	active: boolean;
};

interface EventState {
	events: Event[];
	event?: EditEvt;
	tournaments: Tournament[];
	tournament?: EditTourn;
	draws: Draws;
	loading: boolean;
	send: boolean;
	error?: SerializedError;
	message?: MessageProps;
}

const draws: Draws = [
	{
		draw: 'group',
		en_US: 'Groups',
		pt_BR: 'Grupos',
		symbol: 'img/draws/group.svg',
	},
	{
		draw: 'knockout',
		en_US: 'Knockout',
		pt_BR: 'Eliminatórias',
		symbol: 'img/draws/draw.svg',
	},
];

const initialState: EventState = {
	events: [],
	tournaments: [],
	draws,
	loading: false,
	send: false,
};

export const eventSlice = createSlice({
	name: 'events',
	initialState,
	reducers: {
		newEvent: (state) => {
			state.event = formatEditEvt({
				org: '',
			});
		},
		editEvent: (state, { payload }: PayloadAction<Event>) => {
			state.event = formatEditEvt(payload);
		},
		changeEvent: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof EditEvt;
				value: string | number;
			}>
		) => {
			if (state.event) {
				state.event = {
					...state.event,
					[field]: {
						...(state.event[field] as EditItem<unknown>),
						value,
						changed: (state.event[field] as EditItem<unknown>).value !== value,
						error:
							checkValidity(field, value) ||
							((state.event[field] as EditItem<unknown>).required && !value),
					},
				};
			}
		},
		changeEvtDocument: (
			state,
			{ payload }: PayloadAction<{ field: Document; value: string }>
		) => {
			if (state.event) {
				state.event = {
					...state.event,
					documents: {
						...state.event.documents,
						[payload.field]: {
							value: payload.value,
							changed: true,
							error: !payload.value,
						},
					},
				};
			}
		},
		newTournament: (state) => {
			if (state.event?.id) {
				state.tournament = formatEditTourn({
					event: state.event.id,
					name: '',
					sign: '',
				});
			}
		},
		editTournament: (state, { payload }: PayloadAction<Tournament>) => {
			state.tournament = formatEditTourn(payload);
		},
		changeTournament: (
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: keyof EditTourn;
				value?: string | number | Category | Age | Sport;
			}>
		) => {
			if (state.tournament) {
				state.tournament = {
					...state.tournament,
					[field]: {
						...(state.tournament[field] as EditItem<unknown>),
						value,
						changed:
							(state.tournament[field] as EditItem<unknown>).value !== value,
						error:
							(typeof value !== 'object' && checkValidity(field, value)) ||
							((state.tournament[field] as EditItem<unknown>).required &&
								!value),
					},
				};
			}
		},
		newTournFase: (state, { payload }: PayloadAction<Partial<Fase>>) => {
			if (state.tournament && state.tournament.sport.value) {
				state.tournament.fases = state.tournament.fases.concat(
					formatEditFase(payload)
				);
			}
		},
		changeTournFase: (
			state,
			{
				payload,
			}: PayloadAction<{
				fase: number;
				field: keyof EditFase;
				value?:
					| Fase['criteria']
					| Fase['draw']
					| Fase['pass']
					| Fase['points']
					| Fase['reduct']
					| Fase['earnings']
					| Fase['score']
					| Fase['third'];
			}>
		) => {
			if (state.tournament) {
				state.tournament.fases[payload.fase] = {
					...state.tournament.fases[payload.fase],
					[payload.field]: {
						...(state.tournament.fases[payload.fase][
							payload.field
						] as EditItem<unknown>),
						value: payload.value,
						changed:
							state.tournament.fases[payload.fase][payload.field].changed ||
							(
								state.tournament.fases[payload.fase][
									payload.field
								] as EditItem<unknown>
							).value !== payload.value,
						error:
							(typeof payload.value !== 'object' &&
								checkValidity(payload.field, payload.value)) ||
							((
								state.tournament.fases[payload.fase][
									payload.field
								] as EditItem<unknown>
							).required &&
								!payload.value),
					},
				};
			}
		},
		resetTournFase: (state) => {
			if (state.tournament) {
				state.tournament.fases = [];
			}
		},
		changeCriteriaOrder: (
			state,
			{
				payload,
			}: PayloadAction<{ fase?: number; dragged?: number; dropped?: number }>
		) => {
			if (
				payload.dragged !== undefined &&
				payload.dropped !== undefined &&
				payload.fase !== undefined &&
				state.tournament?.fases[payload.fase].criteria.value
			) {
				const dragged = (
					state.tournament.fases[payload.fase].criteria.value ?? []
				).splice(payload.dragged, 1);

				state.tournament.fases[payload.fase].criteria.value = [
					...(state.tournament.fases[payload.fase].criteria.value ?? []).slice(
						0,
						payload.dropped
					),
					...dragged,
					...(state.tournament.fases[payload.fase].criteria.value ?? []).slice(
						payload.dropped
					),
				];
				state.tournament.fases[payload.fase].criteria.changed =
					!state.tournament.fases[payload.fase].criteria.changed &&
					payload.dragged !== payload.dropped;
			}
		},
		switchCriteria: (
			state,
			{
				payload,
			}: PayloadAction<{
				fase: number;
				sign: Criteria['sign'];
				activate: boolean;
			}>
		) => {
			if (state.tournament?.fases[payload.fase].criteria.value) {
				state.tournament.fases[payload.fase].criteria.value =
					state.tournament.fases[payload.fase].criteria.value?.map(
						(criteria) =>
							criteria.sign === payload.sign
								? { ...criteria, active: payload.activate }
								: criteria
					);
				state.tournament.fases[payload.fase].criteria.changed = true;
			}
		},
		setCriterias: (
			state,
			{
				payload,
			}: PayloadAction<{
				fase: number;
				criterias: Criteria[];
			}>
		) => {
			if (state.tournament?.fases[payload.fase]) {
				state.tournament.fases[payload.fase] = {
					...state.tournament.fases[payload.fase],
					criteria: {
						value: payload.criterias,
						changed: true,
					},
				};
			}
		},
		changeCriteria: (
			state,
			{
				payload,
			}: PayloadAction<{
				fase: number;
				sign: Criteria['sign'];
				value: Criteria['wo'];
			}>
		) => {
			if (state.tournament?.fases[payload.fase].criteria.value) {
				state.tournament.fases[payload.fase].criteria.value =
					state.tournament.fases[payload.fase].criteria.value?.map(
						(criteria) =>
							criteria.sign === payload.sign
								? { ...criteria, wo: payload.value }
								: criteria
					);
				state.tournament.fases[payload.fase].criteria.changed = true;
			}
		},
		removeTournFase: (state, { payload }: PayloadAction<number>) => {
			if (state.tournament) {
				state.tournament.fases.splice(payload, 1);
			}
		},
		setSend: (state) => {
			state.send = true;
		},
		resetMessage: (state) => {
			state.message = undefined;
		},
		resetEvent: (state) => {
			state.event = undefined;
			state.send = false;
		},
		resetEvents: (state) => {
			state.events = [];
			state.loading = false;
			state.error = undefined;
			state.message = undefined;
		},
		resetTournament: (state) => {
			state.tournament = undefined;
			state.send = false;
		},
		resetTournaments: (state) => {
			state.tournaments = [];
			state.loading = false;
			state.error = undefined;
			state.message = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getEvents.fulfilled, (state, { payload }) => {
				state.events = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getEvents.pending, (state) => {
				state.loading = true;
			})
			.addCase(getEvents.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar eventos',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertEvent.fulfilled, (state, { payload }) => {
				state.events = state.events
					.filter((org) => org.id !== payload.id)
					.concat(payload);
				state.event = formatEditEvt(payload);
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'Seu evento foi salvo com sucesso.',
					success: true,
				};
			})
			.addCase(insertEvent.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertEvent.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar evento.',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(switchEvent.fulfilled, (state, { payload }) => {
				state.events = state.events.map((org) =>
					org.id === payload.id ? payload : org
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchEvent.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(switchEvent.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao alterar evento',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(getTournaments.fulfilled, (state, { payload }) => {
				state.tournaments = payload ?? [];
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getTournaments.pending, (state) => {
				state.loading = true;
			})
			.addCase(getTournaments.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao buscar torneios',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(insertTournament.fulfilled, (state, { payload }) => {
				state.tournaments = state.tournaments
					.filter((tourn) => tourn.id !== payload.id)
					.concat(payload);
				state.tournament = formatEditTourn(payload);
				state.loading = false;
				state.error = undefined;
				state.message = {
					header: 'Salvo',
					content: 'Seu torneio foi salvo com sucesso.',
					success: true,
				};
			})
			.addCase(insertTournament.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(insertTournament.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao salvar torneio',
					content: payload?.message,
					error: true,
				};
			});
		builder
			.addCase(switchTournament.fulfilled, (state, { payload }) => {
				state.tournaments = state.tournaments.map((tourn) =>
					tourn.id === payload.id ? payload : tourn
				);
				state.loading = false;
				state.error = undefined;
			})
			.addCase(switchTournament.pending, (state) => {
				state.loading = true;
				state.send = false;
			})
			.addCase(switchTournament.rejected, (state, { payload }) => {
				state.error = payload;
				state.loading = false;
				state.message = {
					header: 'Falha ao alterar torneio',
					content: payload?.message,
					error: true,
				};
			});
	},
});

const formatEditEvt = (
	evt: Pick<EditEvt, 'id'> &
		Pick<
			Event,
			| 'org'
			| 'name'
			| 'edition'
			| 'link'
			| 'poster'
			| 'documents'
			| 'visibility'
			| 'alteration'
			| 'active'
		>
) => {
	return {
		id: evt.id,
		org: {
			value: evt.org,
			changed: false,
			error: !evt.org,
			required: true,
		},
		name: {
			value: evt.name ?? '',
			changed: false,
			error: checkValidity('name', evt.name),
			required: true,
		},
		edition: {
			value: evt.edition ?? new Date().getFullYear(),
			changed: !evt.edition,
			error: false,
			required: true,
		},
		link: {
			value: evt.link ?? '',
			changed: false,
			error: false,
			required: false,
		},
		poster: {
			value: evt.poster,
			changed: false,
			error: false,
			required: false,
		},
		documents: Object.keys(evt.documents ?? {}).reduce(
			(docs, doc) => ({
				...docs,
				[doc]: {
					value: evt.documents?.[doc as Document],
					changed: false,
					error: false,
				},
			}),
			{}
		),
		visibility: {
			value: evt.visibility ?? 'admins',
			changed: !evt.visibility,
			error: false,
			required: false,
		},
		alteration: {
			value: evt.alteration ?? 'admins',
			changed: !evt.alteration,
			error: false,
			required: false,
		},
		active: evt.active ?? true,
	} as EditEvt;
};

const formatEditTourn = (
	tourn: Pick<EditTourn, 'id' | 'event'> &
		Pick<
			Tournament,
			| 'place'
			| 'ranking'
			| 'name'
			| 'sign'
			| 'badge'
			| 'entry'
			| 'begin'
			| 'gender'
			| 'age'
			| 'category'
			| 'fases'
			| 'sport'
			| 'team'
			| 'active'
		>
) => {
	return {
		id: tourn.id,
		event: tourn.event,
		sport: {
			value: tourn.sport,
			changed: false,
			error: !tourn.sport,
			required: true,
		},
		name: {
			value: tourn.name ?? '',
			changed: false,
			error: checkValidity('name', tourn.name),
			required: true,
		},
		sign: {
			value: tourn.sign ?? '',
			changed: false,
			error: !tourn.sign,
			required: true,
		},
		badge: {
			value: tourn.badge,
			changed: false,
			error: false,
			required: false,
		},
		place: {
			value: tourn.place,
			changed: false,
			error: !tourn.place,
			required: true,
		},
		team: {
			value: tourn.team,
			changed: false,
			error: !tourn.team,
			required: true,
		},
		gender: {
			value: tourn.gender,
			changed: false,
			error: !tourn.gender,
			required: true,
		},
		ranking: {
			value: tourn.ranking,
			changed: false,
			error: false,
			required: false,
		},
		age: {
			value: tourn.age,
			changed: false,
			error: false,
			required: false,
		},
		category: {
			value: tourn.category,
			changed: false,
			error: false,
			required: false,
		},
		entry: {
			value: tourn.entry,
			changed: false,
			error: false,
			required: false,
		},
		begin: {
			value: tourn.begin,
			changed: false,
			error: false,
			required: false,
		},
		fases: (tourn.fases ?? []).map((fase) => formatEditFase(fase)),
		active: tourn.active ?? true,
	} as EditTourn;
};

const formatEditFase = (fase: Partial<Fase>) => {
	return {
		score: {
			value: fase?.score,
			changed: false,
			error: !fase?.score,
			required: true,
		},
		draw: {
			value: fase?.draw ?? 'group',
			changed: !fase?.draw,
			error: false,
			required: true,
		},
		pass: {
			value: fase?.pass,
			changed: false,
			error: false,
			required: false,
		},
		third: {
			value: !!fase?.third,
			changed: false,
			error: false,
			required: true,
		},
		points: {
			value: fase?.points,
			changed: false,
			error: false,
			required: false,
		},
		reduct: {
			value: fase?.reduct,
			changed: false,
			error: false,
			required: false,
		},
		earnings: {
			value: fase.earnings,
			changed: false,
			error: false,
			required: false,
		},
		criteria: {
			value: !fase?.draw || fase?.draw === 'group' ? fase?.criteria : undefined,
			changed: false,
			error: false,
			required: false,
		},
	} as EditFase;
};

export const getEvents = createAsyncThunk<
	Event[],
	void,
	{ rejectValue: SerializedError }
>('events/getEvents', async (_, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.events.get();

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertEvent = createAsyncThunk<
	Event,
	Partial<Event>,
	{ rejectValue: SerializedError }
>('events/insertEvent', async (event, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.events.post(event);

		try {
			const documents = await Promise.all(
				Object.keys(event.documents ?? {}).map(async (doc) => {
					const document = event.documents?.[doc as Document];
					const url = data.documents?.[doc as Document];

					if (url && document?.startsWith('blob')) {
						const file = await fetch(document).then((r) => r.blob());

						const savedFile = await api.documents.put(url, file);

						return {
							[doc]:
								savedFile.status === 200
									? `https://docs.pontodoesporte.com.br/events/${event.id}/${doc}.pdf`
									: undefined,
						};
					}

					return {
						[doc]: document,
					};
				})
			);

			return {
				...data,
				documents: {
					...data.documents,
					...documents.reduce(
						(docs, doc) => ({
							...docs,
							...doc,
						}),
						{}
					),
				},
			};
		} catch (error) {
			console.info('Erro ao salvar arquivos.');
			console.error(error);

			return data;
		}
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	} finally {
		setTimeout(() => {
			dispatch(resetMessage());
		}, 10000);
	}
});

export const switchEvent = createAsyncThunk<
	Event,
	{ evt: string; activate: boolean },
	{ rejectValue: SerializedError }
>(
	'events/switchEvent',
	async ({ evt, activate }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.events.switch(evt, activate);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const getTournaments = createAsyncThunk<
	Tournament[],
	string,
	{ rejectValue: SerializedError }
>('events/getTournaments', async (evt, { dispatch, rejectWithValue }) => {
	try {
		await dispatch(authRefresh());

		const { data } = await api.tournaments.get(evt);

		return data;
	} catch (error) {
		return rejectWithValue(
			(error as AxiosError).response?.data as SerializedError
		);
	}
});

export const insertTournament = createAsyncThunk<
	Tournament,
	Partial<Tournament>,
	{ rejectValue: SerializedError }
>(
	'events/insertTournament',
	async (tournament, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.tournaments.post(tournament);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const switchTournament = createAsyncThunk<
	Tournament,
	{
		tourn: string;
		activate?: boolean;
		publish?: boolean;
	},
	{ rejectValue: SerializedError }
>(
	'events/switchTournament',
	async ({ tourn, activate, publish }, { dispatch, rejectWithValue }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.tournaments.switch(tourn, activate, publish);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		} finally {
			setTimeout(() => {
				dispatch(resetMessage());
			}, 10000);
		}
	}
);

export const {
	newEvent,
	editEvent,
	changeEvent,
	changeEvtDocument,
	newTournament,
	editTournament,
	changeTournament,
	newTournFase,
	changeTournFase,
	changeCriteriaOrder,
	changeCriteria,
	switchCriteria,
	setCriterias,
	removeTournFase,
	setSend,
	resetMessage,
	resetTournFase,
	resetTournament,
	resetTournaments,
	resetEvent,
	resetEvents,
} = eventSlice.actions;

export default eventSlice.reducer;
