import { configureStore } from '@reduxjs/toolkit';

import accessReducer from './access';
import allocationReducer from './allocations';
import authReducer from './auth';
import challengeReducer from './challenges';
import champsReducer from './champs';
import classifyReducer from './classify';
import enrollmentsReducer from './enrollments';
import eventReducer from './events';
import groupsReducer from './groups';
import imgsReducer from './images';
import institReducer from './insitutions';
import landingReducer from './landings';
import leagueReducer from './leagues';
import matchesReducer from './matches';
import occasionReducer from './occasion';
import ocupationReducer from './ocupations';
import organizationReducer from './organizations';
import peopleReducer from './people';
import placeReducer from './places';
import planReducer from './plans';
import rankingReducer from './rankings';
import redirectReducer from './redirects';
import rightsReducer from './rights';
import sportsReducer from './sports';
import statsReducer from './statistics';
import tournamentReducer from './tournaments';

const store = configureStore({
	reducer: {
		user: authReducer,
		acs: accessReducer,
		rgt: rightsReducer,
		spr: sportsReducer,
		img: imgsReducer,
		// Owner's configuratons
		org: organizationReducer,
		pln: planReducer,
		evt: eventReducer,
		lag: leagueReducer,
		// App data
		alc: allocationReducer,
		clg: challengeReducer,
		cls: classifyReducer,
		cmp: champsReducer,
		erl: enrollmentsReducer,
		grp: groupsReducer,
		mtc: matchesReducer,
		ocp: ocupationReducer,
		ocs: occasionReducer,
		plc: placeReducer,
		ppl: peopleReducer,
		rkg: rankingReducer,
		stt: statsReducer,
		trn: tournamentReducer,
		lnd: landingReducer,
		rdr: redirectReducer,
		itt: institReducer,
	},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
