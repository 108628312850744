import {
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { Event } from '../events';
import { Organization } from '../organizations';

export type Institution = {
	organization: Organization;
	events: Event[];
};

interface InstitState {
	organization?: Organization;
	events?: Event[];
	loading: boolean;
	error?: SerializedError;
}

const initialState: InstitState = {
	organization: undefined,
	events: undefined,
	loading: false,
	error: undefined,
};

export const institSlice = createSlice({
	name: 'institutions',
	initialState,
	reducers: {
		resetInstit: (state) => {
			state.organization = undefined;
			state.events = undefined;
			state.loading = false;
			state.error = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getInstitution.fulfilled, (state, { payload }) => {
				state.organization = payload.organization;
				state.events = payload.events;
				state.loading = false;
				state.error = undefined;
			})
			.addCase(getInstitution.pending, (state) => {
				state.loading = true;
				state.error = undefined;
			})
			.addCase(getInstitution.rejected, (state, { payload }) => {
				state.loading = false;
				state.error = payload;
			});
	},
});

export const getInstitution = createAsyncThunk<
	Institution,
	{ org: string },
	{ rejectValue: SerializedError }
>(
	'institution/getInstitution',
	async ({ org }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const { data } = await api.institutions.get(org);

			return data;
		} catch (error) {
			return rejectWithValue(
				(error as AxiosError).response?.data as SerializedError
			);
		}
	}
);

export const { resetInstit } = institSlice.actions;

export default institSlice.reducer;
