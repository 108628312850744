import {
	PayloadAction,
	SerializedError,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';

import { api } from '../../api';
import { authRefresh } from '../auth';
import { League, Ranking } from '../leagues';
import { Organization } from '../organizations';

export type Classification = {
	league: League;
	organization: Organization;
	rankings: Ranking[];
};

interface ClassifyState {
	classification?: Classification;
	loading: boolean;
	error?: SerializedError;
}

const initialState: ClassifyState = {
	classification: undefined,
	loading: false,
	error: undefined,
};

export const classifySlice = createSlice({
	name: 'classifications',
	initialState: initialState,
	reducers: {
		resetClassify: (state) => {
			state.classification = undefined;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(
				getClassify.fulfilled,
				(state, { payload }: PayloadAction<Classification>) => {
					state.classification = payload;
					state.loading = false;
					state.error = undefined;
				}
			)
			.addCase(getClassify.pending, (state) => {
				state.loading = true;
			})
			.addCase(getClassify.rejected, (state, action) => {
				state.error = action.error;
				state.loading = false;
			});
	},
});

export const getClassify = createAsyncThunk(
	'classifications/getClassify',
	async ({ rank }: { rank: string }, { rejectWithValue, dispatch }) => {
		try {
			await dispatch(authRefresh());

			const response = await api.classifications.get(rank);

			return response.data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const { resetClassify } = classifySlice.actions;

export default classifySlice.reducer;
